module services {
    export module caseManagement {
        export class sarsCaseService implements interfaces.caseManagement.ISarsCaseService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getOpenCasesForDashboard(countryId: number): ng.resource.IResourceClass<interfaces.caseManagement.OpenCaseDashboard> {
                return this.$resource<interfaces.caseManagement.OpenCaseDashboard>(this.ENV.DSP_URL + "SarsCase/GetOpenCasesForDashboard", {
                    countryId: countryId,
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    });
                
            }
        }
    }
    angular.module("app").service("sarsCaseService", services.caseManagement.sarsCaseService);
}